<template>
  <HXContentCard title="Lista de Empresas">
    <!-- modal logo empresa -->
    <b-modal id="modalLogoEmpresa" title="Logo empresa" size="md" hide-footer>
      <div class="text-center">
        <img
          v-if="logoEmpresa"
          class="img-fluid rounded"
          style="
            max-width: 400px;
            margin-left: 0 auto !important;
            margin-right: 0 auto !important;
          "
          :src="logoEmpresa"
          alt="logo-empresa"
        />
        <p v-else>No hay logo cargado para esta empresa.</p>
      </div>
    </b-modal>
    <div class="text-right px-4 mt-2">
      <div class="d-flex justify-content-end">
        <vs-button
          icon
          animation-type="vertical"
          class="mx-1 text-right"
          @click="$router.push('Empresas_agregar')"
        >
          <i class="fas fa-plus-circle px-1"></i>
          Agregar Empresa
          <template #animate>
            <i class="fas fa-plus-circle"></i>
          </template>
        </vs-button>
      </div>
    </div>
    <!-- end button-sm -->
    <div class="table-responsive p-4">
      <table
        id="datatable_empresa"
        class="table table-bordered table-responsive-md table-striped text-center"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Nombre Empresa</th>
            <th>Imagen</th>
            <th>Sector</th>
            <th>Coreo Electrónico</th>
            <th>Teléfono</th>
            <th>Estado</th>

            <th>Opciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in listaItems" :key="item.id">
            <td>
              <strong>
                {{ index + 1 }}
              </strong>
            </td>
            <td>{{ item.nombre }}</td>
            <td class="px-3 text-center">
              <vs-avatar
                class="hover_avatar"
                @click="
                  logoEmpresa = item.urlLogo;
                  $bvModal.show('modalLogoEmpresa');
                "
              >
                <img
                  v-if="item.urlLogo"
                  class="img-fluid"
                  :src="item.urlLogo"
                  alt="logo-empresa"
                />
                <i v-else class="fas fa-building"></i>
              </vs-avatar>
            </td>
            <td>{{ item.sector.descripcion }}</td>
            <td>{{ item.correo }}</td>
            <td>{{ item.telefonoContacto }}</td>
            <td>{{ item.estado == 1 ? "Activo" : "Inactivo" }}</td>
            <td style="width: 150px !important">
              <ButtonGroup :Id="item.id" :data="dataIcons"></ButtonGroup>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </HXContentCard>
</template>
<script>
import { core } from "../../../config/pluginInit";
import ButtonGroup from "../../../components/ButtonGroup/ButtonGroup.vue";
const Swal = require("sweetalert2");

export default {
  components: {
    ButtonGroup,
  },
  name: "DataTable",
  data() {
    return {
      listaItems: [],
      logoEmpresa: "",
      itemId: null,
      dataIcons: [
        {
          type: "success",
          tooltip: "Editar empresa",
          title: "",
          value: () => 10,
          iconName: "pen",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: (Id) => {
            console.log("Activated");
            this.$router.push("Empresas_editar/" + Id);
          },
          onDeactivate: () => {
            console.log("Deactivate");
          },
        },
        //        {
        //          type: "warning",
        //          tooltip: "Crear lista de deducciones y devengados para nómina ",
        //          title: "",
        //          value: () => 0,
        //          iconName: "cog",
        //          color: "rgba(22, 162, 117, 1)",
        //          tipo: "squared",
        //          animated: "bounce",
        //          onActivate: (Id) => {
        //            this.CrearItemsNomina(Id);
        //            console.log("Activated");
        //          },
        //          onDeactivate: () => {
        //            console.log("Deactivate");
        //          },
        //        },
        {
          type: "danger",
          tooltip: "Eliminar registro",
          title: "",
          value: () => 0,
          iconName: "trash",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: (Id) => {
            console.log("Activated");
            this.deleteRow(Id);
          },
          onDeactivate: () => {
            console.log("Deactivate");
          },
        },
      ],
    };
  },
  async mounted() {
    this.$isLoading(true);
    core.index();
    await this.getData();
    window.$("#datatable_empresa").DataTable({
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
      },
    });
    this.$isLoading(false);
  },
  methods: {
    async CrearItemsNomina(EmpresaId) {
      try {
        this.$isLoading(true);

        let res = await this.$store.dispatch("hl_post", {
          path:
            "NominaLiquidacion/CrearNominaLiquidacionItemsEmpresa/" + EmpresaId,
        });
        this.$isLoading(false);
        if (res)
          Swal.fire("Listo!", "Proceso finalizado correctamente.", "success");
        else
          Swal.fire("Error!", "Ya existen items para esta empresa.", "error");
      } catch (error) {
        this.$isLoading(false);
        console.log("error", error);
        Swal.fire("Error!", "Error: " + error, "error");
      }
    },
    async deleteRow(id) {
      Swal.fire({
        title: "Seguro eliminar registro?",
        text: "Después de eliminarlo no podrás recuperarlo!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          //let loader = this.$loading.show();
          await this.$store.dispatch("hl_get", {
            path: "Empresa/delete/" + id,
          });

          //console.log("res", res);
          //loader.hide()
          await this.getData();

          Swal.fire("Listo!", "Registro eliminado correctamente.", "success");
        }
      });
    },
    async getData() {
      //console.log(this.persona.empresa.id);
      try {
        console.log("get empresas...");
        let res = await this.$store.dispatch("hl_post", {
          path: "Empresa/ListEmpresa",
        });
        console.log("post empresas...", res);
        if (res.length > 0) {
          this.listaItems = res;
        } else {
          this.listaItems = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err", error);
      }
    },
  },
};
</script>
<style>
/* .vs-avatar{
background-color: rgb(255, 255, 255) !important;
}
.vs-avatar:hover{
  background-image: url(https://www.w3schools.com/cssref/img_5terre.jpg);
  img

}  */
</style>
